.dialogTab {
    display: flex;
    flex: 1;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
}

table.MuiTable-root {
    margin-top: auto;
    min-width: max-content;
}

.verticalLine {
    border-left: 0.1em solid black;
    height: auto;
    margin-left: 10px;
    margin-right: 10px;
}

.dialogBoxSmallMobile {
    max-width: 350px;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.dialogBoxMobile {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    flex: 1;

    .MuiDialogContent-root {
        padding: 0;
    }
}

.dialogBox {
    max-width: 2000px;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.dialogActions {
    width: 100%;
    align-self: self-end;
    margin-top: 16px;
}

.dialogActionsNoFullScreen {
    align-self: center;
    justify-self: self-end;
}

.MuiDialogActions-root.dialogActionsMobile {
    align-self: center;
    justify-content: normal;
}

.dialogActions.MuiDialogActions-root {
    padding: 8px 23px;
    justify-content: end;
}

.dialogActions.MuiDialogActions-spacing {
    padding: 8px 23px;
    justify-content: end;
}

.alertStyle.MuiAlert-root {
    margin: 15px 23px 0;
}

.alertTitleStyle.MuiAlertTitle-root {
    font-weight: 600;
}

.changeDateButton {
    min-width: 40px;
    color: grey;
}
